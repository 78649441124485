













import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import CourseBaseForm from '@/components/forms/course/CourseBaseForm.vue'

@Component({
  components: {
    CourseBaseForm,
  },
})
export default class CoursesNew extends Vue {
  private get breadcrumbs () {
    return [
      { name: 'Курсы', path: '/manager/control/courses' },
    ]
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Новый курс',
    }
  }
}
